import React, {useState, useEffect} from 'react'
import {Carousel} from 'react-bootstrap'
import PortableText from './portableText'

const CarouselComponent = ({carousel}) => (
    <>
    <Carousel>
        {carousel.banner && carousel.banner.map((item, i) => {
            return (
            <Carousel.Item key={i}>
                {/* <div className="h-500 h-sm-600 h-lg-800 bg-dark-overlay-1 bg-dark" style={{backgroundImage: 'url(' + item.asset.url + ')', backgroundPosition: 'center center', backgroundSize: 'cover'}}>                
                    <div className="container h-100">
                        <div className="row d-flex h-100">
                            <div className="col-md-8 col-xl-6 justify-content-center align-self-center align-items-start">
                            </div>
                        </div>
                    </div>
                </div> */}

                <img src={item.asset.url} alt={item.alt} style={{width:'100%'}} />
                
            </Carousel.Item>
            )
        })}
    </Carousel>

    <div className="container">
        <h1 className="mt-4" style={{fontSize: '2.75em'}}>
            {carousel._rawText && <PortableText blocks={carousel._rawText} />}
        </h1>
    </div>
    </>
)

export default CarouselComponent